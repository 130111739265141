import React, { useEffect, useState } from 'react'
import { frontend } from './utilities/sessionTypes'

const Schedule = (props) => {
  const [instructor, setInstructor] = useState('')
  const [sessions, setSessions] = useState([])
  const [instructors, setInstructors] = useState([])
  useEffect(() => {
    const sessions = []
    props.sessions?.forEach((s,i) => {
        const row = <tr key={s.day+i}>
          <td>{s.day}</td>
          <td>{s.time}</td>
          <td>{s.topic}</td>
          <td>{s.instructor}</td>
          <button 
            className={
              frontend.includes(s.topic) 
                ? 'register-btn bg-blue' 
                : 'register-btn bg-red'
            }
            onClick={props.setPage('register')}
            
          
          >Go!</button>
          </tr>
        if(!instructors.includes(s.instructor)){
          instructors.push(s.instructor)
        }
        if(!instructor.length){
          sessions.push(row)
        } else {
          if (instructor === s.instructor){
            sessions.push(row)
          }
        }
      })
    setSessions([...sessions])
  },[])
  console.log(sessions)
  return (
    <div className='page-wrapper'>
        <div>Schedule</div>
        <div>
          <label>Instructor</label>
          <select onChange={(e) => setInstructor(e.target.id)}>
            {instructors.map(i => <option id={i}>{i}</option>)}
          </select>
        </div>
        <table>
        <tbody id='schedule-table'>
          <tr><th>Day</th><th>Time</th><th>Topic</th><th>Instructor</th><th>Register</th></tr>
          {sessions}
        </tbody>
        </table>
    </div>
  )
}

export default Schedule