import React from 'react'
import { removeToken } from './utilities/auth'

const Status = ({user, setUser, setPage}) => {
    return (!user ?
    <div className='signin-btn link orange' onClick={() => setPage('signin')}>signin</div> :
    <div className='signin-btn link orange' onClick={() => 
        {
            removeToken()
            setUser(null)
            return setPage('signin')
        }
    }>signout</div>
  )
}

export default Status