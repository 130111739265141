import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useRef } from 'react';
import { stripeSecret } from './network';

const Checkout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const processed = useRef(false)
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event)
    if(!processed.current){
      // Collect payment details and create a payment method
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        console.log('Error:', error.message);
        // Handle the error gracefully
        return;
      }

      // Retrieve the Payment Intent client secret from the server
      const response = await stripeSecret({
        amount: 1000,
        payment_method_types: ["card"],
        customer_id: "test"
      });
      const data = await response.json();
      const { client_secret } = data;

      // Confirm the payment using the retrieved client secret
      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(client_secret, {
        payment_method: paymentMethod.id,
      });
      console.log('error',error, 'confirmError',confirmError)
      if (confirmError) {
        console.log('Error:', confirmError.message);
        alert('Payment failed for this reason: ', confirmError.message)
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded!');
        alert('Payment Successful!')
      } else {
        console.log('Payment failed!');
        // Handle the failed payment
      }
      processed.current=true
    }
  };

  return (
    <div className='page-wrapper column-center'>
      <form className='form-wrapper' onSubmit={handleSubmit}>
        <CardElement />
        <button className='submit-button center-self' type="submit">Pay</button>
      </form>
    </div>
  );
};

export default Checkout;

// import {PaymentElement} from '@stripe/react-stripe-js';    

// const Checkout = () => {
//   const handleSubmit = async (e) => {
//     e.preventDefault()
//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: 'card',
//       card: elements.getElement(CardElement),
//     });
//     if (error) {
//       console.log('Error:', error.message);
//       // Handle the error gracefully
//       return;
//     }

//     // Send the payment method ID to your server for further processing
//     // Make an API call to your server with the payment method ID and any additional data
//     const response = await fetch('/your-server-endpoint', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
//     });

//     if (response.ok) {
//       console.log('Payment successful!');
//       // Do something with the successful payment response
//     } else {
//       console.log('Payment failed!');
//       // Handle the failed payment response
//     }

//   }
//   return (
//     <div className='page-wrapper'>
//       <form onSubmit = {handleSubmit}>
//         <PaymentElement />
//         <button type='button'>Submit</button>
//       </form>
//     </div>
//   );
// };

// export default Checkout;