import React, { useState } from 'react'
import { NextButton, SubmitButton } from './components/buttons'
import { sendApp } from './network'

const Apply = ({setPage}) => {
    const [form, setForm] = useState(0)
    const [removeReasonForLeaving,setRemoveReaasonForLeaving] = useState(false)
    
    const [surname, setSurname] = useState('')
    const [givenName, setGivenName] = useState('')
    const [dob, setDob] = useState('')
    const [email, setEmail] = useState('')
    const [jobCurr, setJobCurr] = useState('')
    const [jobFave, setJobFave] = useState('')
    const [jobDetails, setJobDetails] = useState('')
    const [jobLeave, setJobLeave] = useState('')
    const [science, setScience] = useState('')
    const [timePeriod, setTimePeriod] = useState('')
    const [math, setMath] = useState('')
    const [exercise, setExercise] = useState('')
    const [hobbies, setHobbies] = useState('')
    const [books, setBooks] = useState('')
    const [movies, setMovies] = useState('')
    const [music, setMusic] = useState('')

    const apply = async () => {
        const result = await sendApp({
            surname,
            givenName,
            dob,
            email,
            jobCurr,
            jobFave,
            jobDetails,
            jobLeave,
            science,
            timePeriod,
            math,
            exercise,
            hobbies,
            books,
            movies,
            music
        })
        if(result.ok){
            setForm(4)
        } else {
            setForm(5)
        }
    }
    return (
        <div className='page-wrapper'>
            
            <div className='page-title'>Apply</div>
            {
                form === 0 && 
                <div className='fields-container'>
                    <div className='field-container'>
                        <label>Given name:</label>
                        <input onChange={(e) => setGivenName(e.target.value)} value={givenName}/>
                    </div>
                    <div className='field-container'>
                        <label>Surname:</label>
                        <input onChange={(e) => setSurname(e.target.value)} value={surname}/>
                    </div>
                    <div className='field-container'>
                        <label>Date of Birth:</label>
                        <input onChange={(e) => setDob(e.target.value)} value={dob}/>
                    </div>
                    <div className='field-container'>
                        <label>Email:</label>
                        <input onChange={(e) => setEmail(e.target.value)} value={email}/>
                    </div>
                    <div className='button-container'>
                        <NextButton setter={setForm} curr={form}/>
                    </div>
                </div>
            }
            {
                form === 1 && 
                <div className='fields-container'>
                    <div className='field-container'>
                        <label>Current job:</label>
                        <input onChange={(e) => setJobCurr(e.target.value)} value={jobCurr}/>
                    </div>
                    <div className='long-field-container'>
                        <label>Favorite past job:</label>
                        <div className='row'>
                            <div className='subtext'>
                                Current job is my favorite job:
                            </div>
                            <input 
                                type ='checkbox' 
                                onClick={() => {
                                    setRemoveReaasonForLeaving(!removeReasonForLeaving)
                                }}
                            />
                        </div>
                        <input onChange={(e) => setJobFave(e.target.value)} value={jobFave}/>
                    </div>
                    <div className='long-field-container'>
                        <label>Favorite aspect of this job:</label>
                        <div className='subtext'>Write a paragraph to explain</div>
                        <textarea onChange={(e) => setJobDetails(e.target.value)} value={jobDetails}/>
                    </div>
                    {
                        !removeReasonForLeaving && 
                        <div className='long-field-container'>
                            <label>Reason you're no longer at this job:</label>
                            <div className='subtext'>Write a paragraph to explain</div>
                            <textarea onChange={(e) => setJobLeave(e.target.value)} value={jobLeave}/>
                        </div>
                    }
                    <div className='buttons-container'>
                        <button className='prev-button' onClick={()=> setForm(form-1)}>Prev</button>
                        <NextButton setter={setForm} curr={form}/>
                    </div>
                </div>
            }
            {
                form === 2 && 
                <div className='fields-container'>
                    <div className='long-field-container'>
                        <label>Favorite Science:</label>
                        <div className='subtext'>Use a comma separated list</div>
                        <textarea onChange={(e) => setScience(e.target.value)} value={science}/>
                    </div>
                    <div className='long-field-container'>
                        <label>Favorite Math:</label>
                        <div className='subtext'>Use a comma separated list</div>
                        <textarea onChange={(e) => setMath(e.target.value)} value={math}/>
                    </div>
                    <div className='long-field-container'>
                        <label>Favorite Time Period:</label>
                        <div className='subtext'>Use a comma separated list</div>
                        <textarea onChange={(e) => setTimePeriod(e.target.value)} value={timePeriod}/>
                    </div>
                    <div className='field-container'>
                        <label>Preferred Form of Exercise:</label>
                        <input onChange={(e) => setExercise(e.target.value)} value={exercise}/>
                    </div>
                    <div className='buttons-container'>
                        <button className='prev-button' onClick={()=> setForm(form-1)}>Prev</button>
                        <NextButton setter={setForm} curr={form}/>
                    </div>
                </div>
            }
            {
                form === 3 &&
                <div className='fields-container'>
                    <div className='field-container'>
                        <label>Hobbies:</label>
                        <input onChange={(e) => setHobbies(e.target.value)} value={hobbies}/>
                    </div>
                    <div className='field-container'>
                        <label>Favorite Books:</label>
                        <input onChange={(e) => setBooks(e.target.value)} value={books}/>
                    </div>
                    <div className='field-container'>
                        <label>Favorite Movie/s and Series:</label>
                        <input onChange={(e) => setMovies(e.target.value)} value={movies}/>
                    </div>
                    <div className='field-container'>
                        <label>Favorite Music Genre:</label>
                        <input onChange={(e) => setMusic(e.target.value)} value={music}/>
                    </div>
                    <div className='buttons-container'>
                        <button className='prev-button' onClick={()=> setForm(form-1)}>Prev</button>
                        <SubmitButton setter = {apply} />
                    </div>
                </div>
            }
            {form === 4 &&
                <div>Application received. You'll hear back shortly.</div>
            }
            {form === 5 &&
                <div>Application failed. Try again later.</div>
            }
        </div>
    )
}

export default Apply