import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import "./App.css";
import "./matrix.css";
import Apply from "./Apply";
import Cost from "./Cost";
import Home from "./Home";
import Footer from "./Footer";
import TopNav from "./TopNav";
import Signin from "./Signin";
import Blank from "./Blank";
import { getToken } from "./utilities/auth";
import Enroll from "./Enroll";
import { inject } from "@vercel/analytics";
import Pages from "./Pages";
import Status from "./Status";
import { Matrix } from "./components/matrix";
import { getUser, stripeSecret } from "./network";
import { loadStripe } from "@stripe/stripe-js";

function App() {
  inject();
  const [user, setUser] = useState("");

  const [page, setPage] = useState("");

  const [loading, setLoading] = useState(false);

  const [leftMat, setLeftMat] = useState(<div></div>);
  const [rightMat, setRightMat] = useState(<div></div>);
  const [secret, setSecret] = useState("");
  const firstRun = useRef(true);
  const stripePromise = loadStripe(
    "pk_test_51N4RmJFGKJ4QhOfdqlfrvOOuPZugdVXMq42muXvRv3OLMIdqxEntCbqlKyp2vkwRShWgKAAc2o79WmMeFEIExoH500D9cCkFI3"
  );

  const char = [">", "<", "|", "\\", "/", "[", "_", "`", "-", "^", ":", " "];

  const leftMatrixArr = new Array(41).fill("");
  const rightMatrixArr = new Array(41).fill("");

  leftMatrixArr.forEach(
    (_, i) => (leftMatrixArr[i] = char[Math.floor(Math.random() * 12)])
  );
  rightMatrixArr.forEach(
    (_, i) => (rightMatrixArr[i] = char[Math.floor(Math.random() * 12)])
  );

  useLayoutEffect(() => {
    setTimeout(() => {
      const getRand = Math.floor(Math.random() * 4);
      const newMat = leftMatrixArr.map((el, i) => (
        <div
          key={i + "-l"}
          className={`grid-${getRand}-${Math.floor(i + (getRand % 2))} matrix`}
        >
          {el}
        </div>
      ));
      setLeftMat(newMat);
    }, 200),
      [leftMat];
  });
  useLayoutEffect(() => {
    setTimeout(() => {
      const getRand = Math.floor(Math.random() * 4) + 4;
      const newMat = rightMatrixArr.map((el, i) => (
        <div
          key={i + "-r"}
          className={`grid-${getRand}-${Math.floor(i + (getRand % 2))} matrix`}
        >
          {el}
        </div>
      ));
      setRightMat(newMat);
    }, 200),
      [rightMat];
  });
  // useEffect(() => {
  //   // getLessons()
  // },[page])
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      setLoading(true);
      const token = getToken();
      if (token) {
        getUser(token)
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.un) {
              setUser(data.un);
            } else throw new Error("no auth");
          })
          .catch((err) => console.log(err.message));
        setLoading(false);
      } else {
        setLoading(false);
        setPage("home");
      }
    }
  }, []);
  useEffect(() => {
    stripeSecret({
      amount: 1000,
      payment_method_types: ["card"],
      customer_id: "test",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.client_secret) {
          setSecret(data.client_secret);
        } else throw new Error("payment error");
      })
      .catch((err) => {
        console.log("errr is ", err);
        props.setPage("error");
      });
  }, []);
  const stripeOptions = {
    clientSecret: secret,
  };
  const sessions = [
    {
      day: "Monday",
      time: "1:30pm",
      topic: "HTML",
      instructor: "Stephen Dodd",
    },
    {
      day: "Tuesday",
      time: "9:00am",
      topic: "JavaScript",
      instructor: "Stephen Dodd",
    },
    {
      day: "Wednesday",
      time: "9:00am",
      topic: "HTML",
      instructor: "Stephen Dodd",
    },
    {
      day: "Thursday",
      time: "1:30pm",
      topic: "JavaScript",
      instructor: "Stephen Dodd",
    },
  ];
  const registered = [
    {
      day: "Monday",
      time: "1:30pm",
      topic: "HTML",
      instructor: "Stephen Dodd",
    },
    {
      day: "Tuesday",
      time: "9:00am",
      topic: "JavaScript",
      instructor: "Stephen Dodd",
    },
    {
      day: "Wednesday",
      time: "9:00am",
      topic: "HTML",
      instructor: "Stephen Dodd",
    },
    {
      day: "Thursday",
      time: "1:30pm",
      topic: "JavaScript",
      instructor: "Stephen Dodd",
    },
  ];
  return (
    <div className="App">
      <header>
        <img src={require("./header.png")} />
        <div className="divider" />
        <TopNav page={page} user={user} setPage={setPage} />
      </header>
      <div className="main-container">
        <Matrix matrix={leftMat} />
        <Pages
          sessions={sessions}
          registered={registered}
          setPage={setPage}
          setUser={setUser}
          user={user}
          page={page}
          loading={loading}
          secret={secret}
          stripePromise={stripePromise}
          stripeOptions={stripeOptions}
        />
        <Matrix matrix={rightMat} />
      </div>
      <Status user={user} setPage={setPage} setUser={setUser} />
      <Footer />
    </div>
  );
}

export default App;
