import { getToken } from "./utilities/auth"

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://mathemagical-server.herokuapp.com'

export const sendApp = (body) => {
    return fetch(baseUrl + '/send_app',{
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'content-type': 'application/json'
        }
    })
}

export const sendSignin = (body) => {
    return fetch(baseUrl + '/auth/signin',{
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'content-type': 'application/json'
        }
    })
}

export const getApps = () => {
    return fetch(baseUrl + '/get_app/all',{
        headers: {
            'Authorization': 'Basic ' + getToken()
        }
    })
}

export const getUser = (token) => {
    return fetch(baseUrl + '/auth/get_user',{
        headers: {
            'Authorization': 'Basic ' + token
        }
    })
}
export const deleteApp = (id) => {
    return fetch(baseUrl + `/reject_app/${id}`,{
        method: 'DELETE',
        headers: {
            'Authorization': 'Basic ' + getToken()
        }
    })
}

export const confirmApp = (id) => {
    return fetch(baseUrl + `/approve_app/${id}`,{
        method: 'PUT',
        headers: {
            'Authorization': 'Basic ' + getToken()
        }
    })
}
export const stripeSecret = (data) => {
    return fetch(baseUrl + '/payment/post_intent',{
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-type': 'application/json'
        }
    })
}
