

const Home = (props) => {
    return(
        <div className='page-wrapper'>
            <p>
                Here at Mathemagical, we hold small group classes 
                focused on your specific needs.
            </p>
            <p>  
                Find out more below:
            </p>
                {/*display buttons here */}
            
            <div className = 'teal link' onClick={() => props.setPage('cost')}>
                Cost
            </div>
            <div className='teal link' onClick ={() => props.setPage('apply')}>
                Apply
            </div>
        </div>
    )
}
export default Home