import React from 'react'

const Error = () => {
  return (
    <div className='page-wrapper'>
        <div>An error has occurred. Please try again later</div>
    </div>
  )
}

export default Error