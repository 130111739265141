
export const NextButton = ({curr,setter}) => {
    return(
        <button className = 'next-button' onClick={() => setter(curr + 1)}>
            Next
        </button>
    )
}

export const SubmitButton = ({setter}) => {
    return(
        <button className='submit-button' onClick={()=> setter()}>
            Submit
        </button>
    )
}