import React, { useEffect, useState } from 'react'
import { stripeSecret } from './network'
import StripeContainer from './StripeContainer'

const Register = (props) => {
    const [clicked, setClicked] = useState(false)

    return (
        <div className='page-wrapper'>
            <div>Register</div>
            <div>title</div>
            <div>data</div>
            <button onClick={() => 
                props.setPage('stripe')}>Click</button>
            <div>Description</div>
        </div>
    )
}

export default Register