import React, { useEffect, useState } from 'react'
import { frontend } from './utilities/sessionTypes'

const Sessions = (props) => {
    const [registered, setRegistered] = useState([])

    useEffect(() => {
        const registered = []
        props.registered?.forEach((s,i) => {
            const row = <tr key={s.day+i}>
                <td>{s.day}</td>
                <td>{s.time}</td>
                <td>{s.topic}</td>
                <td>{s.instructor}</td>
                <button className={frontend.includes(s.topic) ? 'register-btn bg-blue' : 'register-btn bg-red'}>Go!</button></tr>
            registered.push(row)
          })
        setRegistered([...registered])
      },[])
  return (
    <div className='page-wrapper'>
    <div>Sessions</div>

    <table id='schedule-table'>
        <tbody>
        <tr><th>Day</th><th>Time</th><th>Topic</th><th>Instructor</th><th>Session</th></tr>
        {registered}
      </tbody>
    </table>
</div>
  )
}

export default Sessions