import React from 'react'

const TopNav = (props) => {
    return (
        <div className='nav-bar'>
            {props.page !== 'home' && !props.user &&
                <div className = 'teal link top' onClick={() => props.setPage('home')}>
                    Home
                </div>
            }
            {props.page !== 'apply' && !props.user &&
                <div className = 'teal link top' onClick={() => props.setPage('apply')}>
                    Apply
                </div>
            }
            {props.page !== 'cost' && !props.user &&
                <div className = 'teal link top' onClick={() => props.setPage('cost')}>
                    Cost
                </div>
            }
            {props.page !== 'schedule' && props.user &&
                <div className = 'teal link top' onClick={() => props.setPage('schedule')}>
                    Available Sessions
                </div>
            }
            {props.page !== 'sessions' && props.user === 'mmAdminDodd' &&
                <div className = 'teal link top' onClick={() => props.setPage('sessions')}>
                    Registered Sessions
                </div>
            }
            {props.page !== 'enroll' && props.user === 'mmAdminDodd' &&
                <div className = 'teal link top' onClick={() => props.setPage('enroll')}>
                    Enroll
                </div>
            }
        </div>
    )
}

export default TopNav