import React, { useState } from 'react'
import { SubmitButton } from './components/buttons'
import { sendSignin } from './network'
import { setToken } from './utilities/auth'

const Signin = ({setPage,setUser}) => {
    const [un, setUn]=useState('')
    const [pw, setPw]=useState('')
    const [err, setErr] = useState(false)
    const signin = async () => {
        let result = await sendSignin({un,pw})
        if(result.ok === true){
            setToken(result.headers.get('X-API-KEY'))
            let body =  await result.json()
            setUser(body.user)
            setPage('schedule')
        } else {
            setErr(true)
        }
    }

  return (
    <div className='page-wrapper'>
        <div className='fields-container'>
            <label>Username</label>
            <input onChange = {(e) => setUn(e.target.value)} value = {un} />
            <label>Password</label>
            <input type='password' onChange = {(e) => setPw(e.target.value)} value = {pw} />
            {err && <div className='orange'>Incorrect Username or Password. Please try again.</div>}
            <div className='button-container'>
                <SubmitButton setter = {signin} />
            </div>
        </div>
    </div>
  )
}

export default Signin