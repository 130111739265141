import React from 'react'
import Apply from './Apply'
import Blank from './Blank'
import Cost from './Cost'
import Enroll from './Enroll'
import Error from './Error'
import Home from './Home'
import Register from './Register'
import Schedule from './Schedule'
import Sessions from './Sessions'
import Signin from './Signin'
import StripeContainer from './StripeContainer'

const Pages = ({
    setPage,
    setUser,
    user,
    page,
    secret,
    stripePromise,
    stripeOptions,
    sessions,
    registered,
    loading
}) => {
    return (
        <div id='page-container'>
            {!user && page === 'home' && <Home setPage={setPage}/>}
            {!user && page === 'cost' && <Cost setPage={setPage} />}
            {!user && page === 'apply' && <Apply setPage={setPage} />}
            {!user && page === 'signin' && <Signin setPage = {setPage} setUser={setUser}/>}
            {user && page === 'schedule' && <Schedule sessions={sessions} setPage = {setPage}/>}
            {user && page === 'sessions' && <Sessions registered={registered} setPage = {setPage}/>}
            {user && page === 'register' && <Register registered={registered} setPage = {setPage}/>}
            {user && page === 'stripe' && <StripeContainer secret={secret=''} stripePromise={stripePromise} options={stripeOptions}/>}
            {page === 'error' && <Error/>}
            {user && page === 'enroll' && <Enroll setPage = {setPage}/>}
            {(!page || loading === true )&& <Blank/>}
        </div>
    )
}

export default Pages