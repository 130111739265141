
import React from 'react'

const Cost = (props) => {
  return (
    <div className='page-wrapper'><br/>


        <p>
            Here at Mathemagical, <span class='orange'>you 
            only pay for the content you need</span>.
            <p>No contracts.</p> 
            <p>No gimmicks.</p>
            <p>No headaches.</p>
        </p><br/><br/>
        <p className='underline'>
            Here's how it works:
        </p><br/>
        <p>
            Each lesson has a pre-test. Pass the
            pre-test: begin the coursework
        </p><br/>
        <p>
            If you need help: attend an instructor-led virtual session at&nbsp;
            <span className='underline'>half price</span><br/><br/><span className='orange'> -or- </span>
            attend a mentor or tutor session.
        </p><br/>
        <p>
            Repeat a classes are always 
            half of the original price.
        </p>
        <a className = 'teal link' onClick ={() => props.setPage('apply')}>
            Apply
        </a>
    </div>
  )
}

export default Cost