import {Elements} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import Checkout from './Checkout';


const StripeContainer = (props) => {

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  console.log(props.stripePromise)
  // if(props.secret.length){
    return (
      <Elements stripe={props.stripePromise} options={props.options}>
        <Checkout />
      </Elements>
    );
  }
// };
export default StripeContainer