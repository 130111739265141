import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { confirmApp, deleteApp, getApps } from './network'
import { getToken } from './utilities/auth'

const Enroll = ({setPage}) => {
    const [apps,setApps] = useState([])
    const [selected,setSelected] = useState(null)
    const firstRender = useRef(true)
    useEffect(()=>{
        (async () =>{
            if(firstRender.current){
                firstRender.current=false
                const result = await getApps()
                const body = await result.json()
                const active = body.filter(app => app.status !== false)
                setApps(active)
            }
        })()
    },[])
    const rejectApp = async (id) => {
        const result = await deleteApp(id)
        if(result.ok){
            const updated = apps.filter(item => item.id !== id)
            setApps([...updated])
        }
    }
    const approveApp = async (id) => {
        const result = await confirmApp(id)
        if(result.ok){
            const updated = apps.filter(item => item.id !== id)
            setApps([...updated])
        }
    }
    const detailRef = useRef(null)

    return (
        <div className='page-wrapper'>
            <div className='black content-row-title btm-bdr'>Applicants</div>
            <div className='content-row orange btm-bdr'><div>Name</div><div>DOB</div>Status</div>
            {apps?.map(el => {
                return(
                    <div className='content-box'>
                        <div className='content-row btm-bdr' onClick={() => {
                            setSelected(el.id)
                            detailRef.current.focus()
                        }}>
                            <div className='content-row-item'>{el.surname}, {el.given}</div>
                            <div className='content-row-item'>{el.dob}</div>
                            <div>
                                <button className='approve-app' onClick={() =>{approveApp(el.id)}}>Approve</button>
                                <button className='reject-app' onClick={() =>{rejectApp(el.id)}}>Reject</button>
                            </div>
                        </div>
                        {
                            selected === el.id && 
                            <div key={'row-'+el.id} className='app-details' >
                                <div className='app-col'>
                                    <label>Current Job</label>
                                    <input readOnly value={el.current_job} ref={detailRef}/>
                                    <label>Past Job</label>
                                    <textarea readOnly value={el.favorite_past_job}/>
                                    <label>Favorite Books</label>
                                    <textarea readOnly value={el.favorite_books}/>
                                    <label>Favorite Hobbies</label>
                                    <textarea readOnly value={el.favorite_books}/>
                                </div>
                                <div className='app-col'>
                                    <label>Reason for Leaving</label>
                                    <textarea readOnly value={el.reason_for_leaving}/>
                                    <label>Favorite Aspect of Job</label>
                                    <textarea readOnly value={el.favorite_aspect}/>
                                    <label>Favorite Exercise</label>
                                    <textarea readOnly value={el.favorite_exercise}/>
                                    <label>Favorite Movies</label>
                                    <textarea readOnly value={el.favorite_movies}/>
                                </div>
                                <div className='app-col'>
                                    <label>Math</label>
                                    <textarea readOnly value={el.favorite_math}/>
                                    <label>Science</label>
                                    <textarea readOnly value={el.favorite_science}/>
                                    <label>Time Period</label>
                                    <textarea readOnly value={el.favorite_time_period}/>
                                    <label>Favorite Music</label>
                                    <textarea readOnly value={el.favorite_music}/>
                                </div>
                            </div>
                        }
                    </div>
                    )
                }
            )}
            {(apps.length === 0) && <div className='orange'> Not Authorized.</div>}
        </div>
    )
}

export default Enroll